<nav class="main">
  <ul class="links">
    <li class="linksItem">
      <a href={{routes.PARTICIPANTES}}>
        {{"MENU.PARTICIPANTES" | translate}}
      </a>
    </li>
    <li class="linksItem">
      <a href={{routes.BRACKET}}>
        {{"MENU.BRACKET" | translate}}
      </a>
    </li>
    <li class="linksItem">
      <a href={{routes.RULESET}} target="_blank">
        {{"MENU.RULESET" | translate}}
      </a>
    </li>
  </ul>
</nav>
