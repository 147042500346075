import { Component, OnInit } from '@angular/core';
import { TranslateService } from '../../../../node_modules/@ngx-translate/core';

@Component({
  selector: 'app-participants',
  templateUrl: './participants.component.html',
  styleUrls: ['./participants.component.scss']
})
export class ParticipantsComponent implements OnInit {

  public TEAM_1 = [
   {
      logo: 'assets/Fotos/Cheeto.png',
      name: 'Cheeto',
      buttonLabel: 'Canal de Twitch',
      buttonHref:
        'https://twitch.tv/srcheeto',
      isTwitch: true
    },
    {
      logo: 'assets/Fotos/Rubius.png',
      name: 'Rubius',
      buttonLabel: 'Canal de Twitch',
      buttonHref:
        'https://twitch.tv/rubius',
      isTwitch: true
    },
    {
      logo: 'assets/Fotos/Orslok.png',
      name: 'Orslok',
      buttonLabel: 'Canal de Twitch',
      buttonHref:
        'https://twitch.tv/orslok',
      isTwitch: true
    }
  ];

  public TEAM_2 = [
    {
      logo: 'assets/Fotos/Daz.png',
      name: 'Daz',
      buttonLabel: 'Canal de Twitch',
      buttonHref:
        'https://twitch.tv/dazrbn',
      isTwitch: true
    },
    {
      logo: 'assets/Fotos/Folagor.png',
      name: 'Folagor',
      buttonLabel: 'Canal de Twitch',
      buttonHref:
        'https://twitch.tv/folagorlives',
      isTwitch: true
    },
    {
      logo: 'assets/Fotos/Frigo.png',
      name: 'FrigoAdri',
      buttonLabel: 'Canal de Twitch',
      buttonHref:
        'https://twitch.tv/frigoadri',
      isTwitch: true
    }
  ];

  public TEAM_3 = [
    {
      logo: 'assets/Fotos/Sekiam.png',
      name: 'Sekiam',
      buttonLabel: 'Canal de Twitch',
      buttonHref:
        'https://twitch.tv/sekiam',
      isTwitch: true
    },
    {
      logo: 'assets/Fotos/Mangel.png',
      name: 'Mangel',
      buttonLabel: 'Canal de Twitch',
      buttonHref:
        'https://twitch.tv/mangel',
      isTwitch: true
    },
    {
      logo: 'assets/Fotos/IlloJuan.png',
      name: 'IlloJuan',
      buttonLabel: 'Canal de Twitch',
      buttonHref:
        'https://twitch.tv/illojuan',
      isTwitch: true
    }
  ];

  public TEAM_4 = [
    {
      logo: 'assets/Fotos/DarioMH.png',
      name: 'DarioMH',
      buttonLabel: 'Canal de Twitch',
      buttonHref:
        'https://twitch.tv/darioemehache',
      isTwitch: true
    },
    {
      logo: 'assets/Fotos/Maximus.jpg',
      name: 'Maximus',
      buttonLabel: 'Canal de Twitch',
      buttonHref:
        'https://twitch.tv/agentemaxo',
      isTwitch: true
    },
    {
      logo: 'assets/Fotos/Sliver.png',
      name: 'Sliver',
      buttonLabel: 'Canal de Twitch',
      buttonHref:
        'https://twitch.tv/sliverrk',
      isTwitch: true
    }
  ];

  public TEAM_5 = [
    {
      logo: 'assets/Fotos/Chuso.png',
      name: 'Chuso',
      buttonLabel: 'Canal de Twitch',
      buttonHref:
        'https://twitch.tv/chusommontero',
      isTwitch: true
    },
    {
      logo: 'assets/Fotos/Abby.png',
      name: 'Abby',
      buttonLabel: 'Canal de Twitch',
      buttonHref:
        'https://twitch.tv/littleragergirl',
      isTwitch: true
    },
    {
      logo: 'assets/Fotos/Papi-Gavi.png',
      name: 'Gavi',
      buttonLabel: 'Canal de Twitch',
      buttonHref:
        'https://twitch.tv/papigavitv',
      isTwitch: true
    }
  ];

  public TEAM_6 = [
    {
      logo: 'assets/Fotos/Hardy.png',
      name: 'Hardy',
      buttonLabel: 'Canal de Twitch',
      buttonHref:
        'https://twitch.tv/hardyluski',
      isTwitch: true
    },
    {
      logo: 'assets/Fotos/Trolero.png',
      name: 'Trolero',
      buttonLabel: 'Canal de Twitch',
      buttonHref:
        'https://twitch.tv/trolerotutos',
      isTwitch: true
    },
    {
      logo: 'assets/Fotos/DSimphony.png',
      name: 'Dsimphony',
      buttonLabel: 'Canal de YouTube',
      buttonHref:
        'youtube.com/c/DSimphony/videos',
      isTwitch: false
    }
  ];

  public TEAM_7 = [
    {
      logo: 'assets/Fotos/Luh.png',
      name: 'Luh',
      buttonLabel: 'Canal de Twitch',
      buttonHref:
        'https://twitch.tv/luh',
      isTwitch: true
    },
    {
      logo: 'assets/Fotos/MerySoldier.png',
      name: 'Mery Soldier',
      buttonLabel: 'Canal de Twitch',
      buttonHref:
        'https://twitch.tv/mery_soldier',
      isTwitch: true
    },
    {
      logo: 'assets/Fotos/Blessur.png',
      name: 'Bl3ssur',
      buttonLabel: 'Canal de Twitch',
      buttonHref:
        'https://twitch.tv/bl3ssur',
      isTwitch: true
    }
  ];

  public TEAM_8 = [
    {
      logo: 'assets/Fotos/Sara-Pecas.png',
      name: 'Sara Pecas',
      buttonLabel: 'Canal de Twitch',
      buttonHref:
        'https://twitch.tv/sara_pecas',
      isTwitch: true
    },
    {
      logo: 'assets/Fotos/Elesky.png',
      name: 'Elesky',
      buttonLabel: 'Canal de Twitch',
      buttonHref:
        'https://twitch.tv/elesky25',
      isTwitch: true
    },
    {
      logo: 'assets/Fotos/Ray-Bacon.png',
      name: 'Ray Bacon',
      buttonLabel: 'Canal de Twitch',
      buttonHref:
        'https://twitch.tv/epicraybacon',
      isTwitch: true
    }
  ];

  public bigDesktopDecorationRight: string = '/assets/Fotos/Baby-luigi.png';
  public bigDesktopDecorationLeft: string = '/assets/Fotos/Bowser.png';
  public bigDesktopDecorationImgLeftDown: string = '/assets/Fotos/Baby-mario.png';

  constructor(public translate: TranslateService) {
    this.translate.addLangs(['en', 'es']);
    this.translate.setDefaultLang('es');
  }

  ngOnInit(): void {
  }

}
