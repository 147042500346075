import { Component } from '@angular/core';
import { TranslateService } from '../../node_modules/@ngx-translate/core';
import { Router, NavigationEnd } from '@angular/router';

declare let gtag: Function;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'vr-circuit-webpage';
  tablet = 768;
  desktop = 1025;
  bigDesktop = 1400;

  constructor(public translate: TranslateService, public router: Router) {
    this.translate.addLangs(['en', 'es']);
    this.translate.setDefaultLang('es');
    this.router.events.subscribe(event => {
       if(event instanceof NavigationEnd){
           gtag('config', 'G-8XZ6SDZVEB',
               {
                 'page_path': event.urlAfterRedirects
               }
              );
        }
     });
  }
}
