import { Component, OnInit } from '@angular/core';
import { routes } from '../../routes';
import { TranslateService } from '../../../../node_modules/@ngx-translate/core';

@Component({
  selector: 'app-desktopmenu',
  templateUrl: './desktopmenu.component.html',
  styleUrls: ['./desktopmenu.component.scss']
})
export class DesktopmenuComponent implements OnInit {

  public routes = routes;

  constructor(public translate: TranslateService) {
    this.translate.addLangs(['en', 'es']);
    this.translate.setDefaultLang('es');
  }

  ngOnInit(): void {

  }

}
