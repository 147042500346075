<div class="main" id="participantes">
  <div class="row">
    <div class="header">
      <h1 class="title">{{"PARTICIPANTS.TITLE" | translate}}</h1>
    </div>
  </div>

  <!-- EQUIPO 1 -->
  <div class="row">
    <div class="header">
      <h3 class="teamTitle">Equipo 1</h3>
    </div>
  </div>
  <div class="mobileParticipantsListContainer">
    <div class="row">
      <div class="eventsMobile">
        <div *ngFor="let participant of TEAM_1">
          <app-card [participant]="participant"></app-card>
        </div>
      </div>
    </div>
  </div>

  <div class="desktopParticipantsListContainer">
    <div class="row">
      <ul class="desktopEvents">
        <div *ngFor="let participant of TEAM_1">
          <app-card [participant]="participant"></app-card>
        </div>
      </ul>
    </div>
  </div>

  <!-- EQUIPO 2 -->
  <div class="row">
    <div class="header">
      <h3 class="teamTitle">Equipo 2</h3>
    </div>
  </div>
  <div class="mobileParticipantsListContainer">
    <div class="row">
      <div class="eventsMobile">
        <div *ngFor="let participant of TEAM_2">
          <app-card [participant]="participant"></app-card>
        </div>
      </div>
    </div>
  </div>

  <div class="desktopParticipantsListContainer">
    <div class="row">
      <ul class="desktopEvents">
        <div *ngFor="let participant of TEAM_2">
          <app-card [participant]="participant"></app-card>
        </div>
      </ul>
    </div>
  </div>

  <!-- EQUIPO 3 -->
  <div class="row">
    <div class="header">
      <h3 class="teamTitle">Equipo 3</h3>
    </div>
  </div>
  <div class="mobileParticipantsListContainer">
    <div class="row">
      <div class="eventsMobile">
        <div *ngFor="let participant of TEAM_3">
          <app-card [participant]="participant"></app-card>
        </div>
      </div>
    </div>
  </div>

  <div class="desktopParticipantsListContainer">
    <div class="row">
      <ul class="desktopEvents">
        <div *ngFor="let participant of TEAM_3">
          <app-card [participant]="participant"></app-card>
        </div>
      </ul>
    </div>
  </div>

  <!-- EQUIPO 4 -->
  <div class="row">
    <div class="header">
      <h3 class="teamTitle">Equipo 4</h3>
    </div>
  </div>
  <div class="mobileParticipantsListContainer">
    <div class="row">
      <div class="eventsMobile">
        <div *ngFor="let participant of TEAM_4">
          <app-card [participant]="participant"></app-card>
        </div>
      </div>
    </div>
  </div>

  <div class="desktopParticipantsListContainer">
    <div class="row">
      <ul class="desktopEvents">
        <div *ngFor="let participant of TEAM_4">
          <app-card [participant]="participant"></app-card>
        </div>
      </ul>
    </div>
  </div>

  <!-- EQUIPO 5 -->
  <div class="row">
    <div class="header">
      <h3 class="teamTitle">Equipo 5</h3>
    </div>
  </div>
  <div class="mobileParticipantsListContainer">
    <div class="row">
      <div class="eventsMobile">
        <div *ngFor="let participant of TEAM_5">
          <app-card [participant]="participant"></app-card>
        </div>
      </div>
    </div>
  </div>

  <div class="desktopParticipantsListContainer">
    <div class="row">
      <ul class="desktopEvents">
        <div *ngFor="let participant of TEAM_5">
          <app-card [participant]="participant"></app-card>
        </div>
      </ul>
    </div>
  </div>

  <!-- EQUIPO 6 -->
  <div class="row">
    <div class="header">
      <h3 class="teamTitle">Equipo 6</h3>
    </div>
  </div>
  <div class="mobileParticipantsListContainer">
    <div class="row">
      <div class="eventsMobile">
        <div *ngFor="let participant of TEAM_6">
          <app-card [participant]="participant"></app-card>
        </div>
      </div>
    </div>
  </div>

  <div class="desktopParticipantsListContainer">
    <div class="row">
      <ul class="desktopEvents">
        <div *ngFor="let participant of TEAM_6">
          <app-card [participant]="participant"></app-card>
        </div>
      </ul>
    </div>
  </div>

  <!-- EQUIPO 7 -->
  <div class="row">
    <div class="header">
      <h3 class="teamTitle">Equipo 7</h3>
    </div>
  </div>
  <div class="mobileParticipantsListContainer">
    <div class="row">
      <div class="eventsMobile">
        <div *ngFor="let participant of TEAM_7">
          <app-card [participant]="participant"></app-card>
        </div>
      </div>
    </div>
  </div>

  <div class="desktopParticipantsListContainer">
    <div class="row">
      <ul class="desktopEvents">
        <div *ngFor="let participant of TEAM_7">
          <app-card [participant]="participant"></app-card>
        </div>
      </ul>
    </div>
  </div>

  <!-- EQUIPO 8 -->
  <div class="row">
    <div class="header">
      <h3 class="teamTitle">Equipo 8</h3>
    </div>
  </div>
  <div class="mobileParticipantsListContainer">
    <div class="row">
      <div class="eventsMobile">
        <div *ngFor="let participant of TEAM_8">
          <app-card [participant]="participant"></app-card>
        </div>
      </div>
    </div>
  </div>

  <div class="desktopParticipantsListContainer">
    <div class="row">
      <ul class="desktopEvents">
        <div *ngFor="let participant of TEAM_8">
          <app-card [participant]="participant"></app-card>
        </div>
      </ul>
    </div>
  </div>
  <img class="bigDesktopDecorationImgLeft" src={{bigDesktopDecorationLeft}} alt="" />
  <img class="bigDesktopDecorationImgRight" src={{bigDesktopDecorationRight}} alt="" />
  <img class="bigDesktopDecorationImgLeftDown" src={{bigDesktopDecorationImgLeftDown}} alt="" />
</div>
