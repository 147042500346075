<div>
  <div class="main">
    <div class="leaderImageContainer">
      <img src="{{participant.logo}}" alt="" />
    </div>
    <h5 class="title">{{participant.name}}</h5>
    <div class="wrapper" *ngIf="participant.isTwitch">
      <a class="buttonTwitch"  href={{participant.buttonHref}} target="_blank">
        <span class="buttonText">{{participant.buttonLabel}}</span>
      </a>
    </div>
    <div class="wrapper" *ngIf="!participant.isTwitch">
      <a class="buttonYoutube" href={{participant.buttonHref}} target="_blank">
        <span class="buttonText">{{participant.buttonLabel}}</span>
      </a>
    </div>
  </div>
</div>
