export const routes = {
  // Home page ids
  PARTICIPANTES: '/#participantes',
  BRACKET: '/emparejamientos', // Esto llevará a una pantalla de emparejamientos despues del sorteo
  RANKING: '/ranking',
  RULESET: 'https://victoryroadvgc.com/es/2021/03/10/presentacion-mario-kart-challenge/', // Esto lleva al post de la web
  INTRODUCTION: 'https://victoryroadvgc.com/es/2021/03/10/presentacion-mario-kart-challenge/', // Esto lleva al post de la web

  // External pages
  TRAILER: 'https://twitter.com/VictoryRoadES/status/1369763587481288712?s=20', // Esto lleva al hilo de Twitter
  ABOUT_VGC: 'https://victoryroadvgc.com/en/pokemon-vgc/',
  VICTORY_ROAD: 'https://victoryroadvgc.com/',

  // Docs
  SIGNUP_CONDITIONS_PDF: encodeURIComponent(
    'Victory Road Circuit - Signup conditions.pdf'
  ),
  LEGAL_NOTICE_PDF: encodeURIComponent('Victory Road Circuit - Legal notice.pdf'),
};
